<template>
  <b-spinner v-if="loading" variant="primary"></b-spinner>
  <div v-else-if="prenotazioni && prenotazioni.length > 0">
    <div v-for="(prenotazione, ind) in prenotazioni" :key="ind">
      <CardPrenotazioneCittadino
        :prenotazione="prenotazione"
        @download-pdf="downloadPdf(ind)"
        @download-calendar="downloadCalendar(ind)"
        @show-detail="showDetail(ind)"
        class="mb-3"
      />
    </div>

    <b-modal
      size="xl"
      centered
      id="modal-dettagliorec"
      ref="modal-dettagliorec"
      hide-footer
      hide-header
      scrollable
      body-class="p-0"
      @hidden="selectedIndex = null"
    >
      <b-spinner variant="secondary" label="loading" v-if="loading"></b-spinner>
      <DettaglioPrenotazioneCittadino
        :reservation="prenotazioni[selectedIndex]"
        @handle-status-update="handleStatusUpdate($event)"
        @handle-notes-update="handleNotesUpdate($event)"
        @fetch-data="fetchData()"
        @handle-update-request="handleUpdateRequest()"
      />
    </b-modal>
    <div v-if="prenotazioni && prenotazioni.length > 0">
      <div class="d-flex">
        <span class="text-info mr-1" v-if="total > 0"
          >{{ fromPage }}-{{ toPage }} di
        </span>
        <span class="text-info"> {{ total }}</span>
      </div>
      <b-pagination
        v-model="filter.page"
        :total-rows="total"
        :per-page="10"
        align="center"
        @change="paginationChanged"
        :size="$root.isMobile ? 'sm' : 'lg'"
      ></b-pagination>
    </div>
  </div>
  <div v-else>Nessuna prenotazione.</div>
</template>
<script>
import { reservationService } from "@/_services"
import { mapActions } from "vuex"
import * as moment from "moment/moment"
import DettaglioPrenotazioneCittadino from "@/components/prenotazioni/DettaglioPrenotazioneCittadino.vue"
import CardPrenotazioneCittadino from "@/components/prenotazioni/CardPrenotazioneCittadino.vue"

export default {
  name: "UserStorico",
  components: {
    DettaglioPrenotazioneCittadino,
    CardPrenotazioneCittadino
  },
  data() {
    return {
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill"
      },

      loading: true,
      prenotazioni: null,
      selectedIndex: null,
      statusPositive: {
        1: "secondary",
        2: "info",
        3: "info",
        4: "primary",
        5: "ter-4",
        6: "ter-4"
      },
      statusNegative: {
        1: "danger",
        2: "ter-2",
        3: "ter-2",
        4: "dark"
      },
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check"
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary"
      },
      countPrev: 0,
      count: 0,
      total: 0,
      fromPage: 0,
      toPage: 0,
      filter: { page: 0 }
    }
  },
  watch: {
    filter: {
      handler(prev, post) {
        this.prenotazioni = []
        this.fetchData()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    fetchData() {
      var self = this
      self.loading = true
      // console.log(this.filter);

      reservationService
        .allUserReservations(this.filter)
        .then(function (response) {
          // console.log("response fetch prenotazioni", response.data.data);
          self.prenotazioni = response.data.data.data
          self.count = response.data.data.length
          self.filter.page = response.data.data.current_page
          self.total = response.data.data.total
          self.fromPage = response.data.data.from
          self.toPage = response.data.data.to
          self.loading = false
        })
        .catch(function (error) {
          self.errorAlert(
            "non è stato possibile recuperare le prenotazioni recenti"
          )
          self.loading = false
          console.log("error fetch prenotazioni", error)
        })
    },
    showDetail(index) {
      this.selectedIndex = index
      this.$refs["modal-dettagliorec"].show()
    },
    formatData(data) {
      return moment(data).format(" HH:mm dddd DD MMM YYYY")
    },
    formatDataNew(data) {
      return moment(data).format(" HH:mm - DD/MM/YYYY")
    },
    handleStatusUpdate(data) {
      // console.log("data to submit", data);
      const item = this.prenotazioni[this.selectedIndex]
      var self = this
      if (!data.confirmed) {
        var form = data.message ? { message: data.message } : {}
        reservationService
          .userCancelOrReject(form, data.action, item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
          })
        this.$refs["modal-dettagliorec"].hide()
      } else {
        reservationService
          .userConfirmOrder(item.hashid)
          .then(function (re) {
            window.scrollTo(0, 0)
            self.successAlert("Richiesta aggiornata con successo")
            self.fetchData()
          })
          .catch(function (error) {
            self.loading = false
            window.scrollTo(0, 0)
            self.errorAlert("non è stato possibile effettuare l'operazione")
          })
        this.$refs["modal-dettagliorec"].hide()
      }
    },
    downloadPdf(ind) {
      const hash = this.prenotazioni[ind].hashid
      const fName = this.prenotazioni[ind].codice_richiesta
      var self = this
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    downloadCalendar(ind) {
      const hash = this.prenotazioni[ind].hashid
      const fName = this.prenotazioni[ind].codice_richiesta
      var self = this
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".ics"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    paginationChanged() {
      // console.log("PAG CHANGED");
    },
    handleNotesUpdate(data) {
      this.prenotazioni[this.selectedIndex].user_notes = data.user_notes
    },
    handleUpdateRequest() {
      this.$bvModal.hide("modal-dettagliorec")
      this.successAlert("richiesta inviata con successo")
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
<style>
.d__innline {
  display: inline;
}
</style>
