<template>
  <div>
    <div class="col-12 px-0 text-center mb-4">
      <b-nav
        v-if="innerWidth > 768"
        class="text-prim-grad-1 justify-content-between shadow_2 bg-white general__border"
      >
        <b-nav-item
          class=""
          disabled
          :active="step == 0"
          :class="step > 0 && step < 6 ? 'done' : ''"
          >Tipo</b-nav-item
        >
        <b-nav-item
          class=""
          disabled
          :active="step == 1"
          :class="step > 1 && step < 6 ? 'done' : ''"
          >Per</b-nav-item
        >
        <b-nav-item
          class=""
          disabled
          :active="step == 2"
          :class="step > 2 && step < 6 ? 'done' : ''"
          >Presso
        </b-nav-item>
        <b-nav-item
          class=""
          disabled
          :active="step == 3"
          :class="step > 3 && step < 6 ? 'done' : ''"
          >Oggetto
        </b-nav-item>
        <b-nav-item
          disabled
          class=""
          :active="step == 4"
          :class="step > 4 && step < 6 ? 'done' : ''"
          >Data</b-nav-item
        >
        <b-nav-item
          disabled
          class=""
          :active="step == 5"
          :class="step > 5 && step < 6 ? 'done' : ''"
          >Conferma
        </b-nav-item>
        <b-nav-item disabled :active="step == 6" :class="step > 6 ? 'done' : ''"
          >Riepilogo
          <b-icon
            v-if="reservationConfirmed && newReservation"
            icon="check2-circle"
          ></b-icon>
        </b-nav-item>
      </b-nav>
      <NavBarNuovoOrdineMobile :steps="steps" :current-step="step" v-else />
    </div>

    <div class="col-12 px-0 text-center">
      <transition-group name="slideside">
        <div v-if="step == 0" key="step0" class="">
          <b-row cols="1" class="my-1 py-2">
            <h5 class="text-prim-grad-1 text-left pb-4">Cosa vuoi fare?</h5>
            <b-row class="d-flex mt-n3" align-v="stretch">
              <b-col
                @click="form.type = 'prodotto'"
                class="mt-3 px-0 font-weight-bolder w-100 point col-12 col-md-5 col-lg-3 col-xl-2"
              >
                <div
                  class="mr-3 puntofarmaCard p-2 py-4 h-100 d-flex align-items-center justify-content-center"
                  :class="{
                    'secondary_bg_gradient text-white': form.type === 'prodotto'
                  }"
                >
                  Ordinare un prodotto
                </div>
              </b-col>

              <b-col
                @click="form.type = 'prestazione'"
                class="mt-3 px-0 font-weight-bolder w-100 point col-12 col-md-5 col-lg-3 col-xl-2"
              >
                <div
                  class="mr-3 puntofarmaCard p-2 py-4 h-100 d-flex align-items-center justify-content-center"
                  :class="{
                    'secondary_bg_gradient text-white':
                      form.type === 'prestazione'
                  }"
                >
                  Prenotare una prestazione
                </div>
              </b-col>

              <b-col
                class="mt-3 px-0 font-weight-bolder w-100 point col-12 col-md-5 col-lg-3 col-xl-2"
                @click="form.type = 'teleconsulto'"
              >
                <div
                  class="mr-3 puntofarmaCard p-2 py-4 h-100 d-flex align-items-center justify-content-center"
                  :class="{
                    'secondary_bg_gradient text-white':
                      form.type === 'teleconsulto'
                  }"
                >
                  Prenotare un teleconsulto
                </div>
              </b-col>
            </b-row>
            <span id="moveOn"></span>
          </b-row>
        </div>
        <div v-if="step == 1" key="step1" class="">
          <b-row cols="1" class="my-1 py-2">
            <h5 class="text-prim-grad-1 text-left pb-4">
              Per chi è la prenotazione?
            </h5>

            <SubjectSelection
              v-on:selected-subject="setRequestSubject($event)"
            />
            <span id="moveOn"></span>
          </b-row>
        </div>
        <div v-if="step == 2" key="step2">
          <b-row cols="1" class="my-1">
            <h5 class="text-prim-grad-1 text-left pb-4">
              Scegli presso chi effettuare la prenotazione
            </h5>
            <b-row
              align-v="stretch"
              class="d-flex flex-nowrap overflow-auto flex-md-wrap"
            >
              <div
                v-for="(azienda, ind) in user.my_circle"
                :key="ind"
                class="mr-4 mb-4"
              >
                <CompanyCard
                  class="h-100"
                  :azienda="azienda"
                  :is-selected="enlight(azienda)"
                  :show-heart="true"
                  @toggle-preferito="togglePreferito($event)"
                  @company-selection="selectCompany($event)"
                />
              </div>
              <b-col class="my-2">
                <div class="align-content-center h-100 d-flex">
                  <b-button
                    class="text-left my-auto"
                    @click="$refs['modal-preferenze'].show()"
                  >
                    <b-icon icon="plus"></b-icon> cerca nuovo
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-row>
        </div>
        <div v-if="step == 3" key="step3" id="step-3-conteiner">
          <b-row cols="1" class="m-0">
            <h5 class="d-flex text-prim-grad-1 text-left pb-0 mb-0">
              <span v-if="form.type === 'prestazione'">
                Nuova richiesta per prestazione
              </span>
              <div
                class="d-flex mb-0 inline-block"
                v-else-if="form.type === 'prodotto'"
              >
                <span>Nuovo ordine</span>
                <b-icon
                  class="ml-2 mb-4"
                  icon="question-circle"
                  variant="primary"
                  id="new-order-explain"
                ></b-icon>
              </div>
              <span v-else> Nuova richiesta di {{ form.type }} </span>
            </h5>
          </b-row>

          <b-tooltip
            placement="rightbottom"
            variant="primary custom"
            target="new-order-explain"
            triggers="hover"
          >
            <p>
              Puoi effettuare un ordine ricercando i prodotti che ti interessano
              oppure inserendo una ricetta. I farmaci inclusi nella ricetta non
              devono essere inseriti manualmente.
            </p>
          </b-tooltip>

          <ProductSelection
            v-if="form.type === 'prodotto' && !block"
            :preselected="productOrder"
            @selected-product="setRequestProduct($event)"
            @uploaded-order="setFileOrder($event)"
          />
          <PrestazioneSelection
            class="mt-2"
            v-if="form.type === 'prestazione'"
            :company="selectedCompany"
            @selected-prestazione="setRequestPrestazione($event)"
            :preselected="selectedPrestazione"
          />
          <TeleconsultoSurveySelection
            v-if="form.type === 'teleconsulto'"
            :company="selectedCompany"
            :selectable="!selectTcOptionDisabled"
            @selected-tcsurvey="setRequestTcSurvey($event)"
          />
        </div>

        <div v-if="step == 4" key="step4">
          <DatetimeSelection
            v-if="innerWidth > 768"
            @update-durata="durata = $event"
            :notitle="true"
            :company="form.company_id"
            :service="form.service_id"
            :hservice="selectedPrestazione && selectedPrestazione.hashid"
            :restype="form.type"
            @date-selected="
              form.data_inizio = $event
              waitForInput = false
            "
          ></DatetimeSelection>

          <CalendarioDisponibilitaGiornalieraCittadino
            v-else
            @update-durata="durata = $event"
            @update-data-inizio="
              form.data_inizio = $event
              waitForInput = false
            "
            :form="form"
            :selected-prestazione="selectedPrestazione"
          />
        </div>
        <div v-if="step == 5" key="step5">
          <b-row
            cols="1"
            v-if="showSurvey && surveyToFill"
            class="my-2"
            ref="surveystep"
          >
            <h5 class="text-prim-grad-1 text-left pb-4">
              Compila il questionario
            </h5>
            <b-col>
              <FillSurvey
                :survey="surveyToFill.template"
                v-on:end-quest="handleFilledSurvey($event)"
              />
            </b-col>
          </b-row>
          <b-row cols="1" v-else-if="form.data_inizio" class="my-2">
            <h5 class="text-prim-grad-1 text-left pb-4">Conferma</h5>
            <div class="puntofarmaCard p-4 mb-2">
              <b-col class="px-0">
                <div class="mt-2">
                  <p class="text-left text-capitalize">
                    <span class="text-primary">Tipo ordine:</span>
                    {{ form.type }}
                    <span class="underlined" v-if="selectedPrestazione">
                      {{ selectedPrestazione.name }}</span
                    >
                    <b-badge
                      variant="info"
                      class="px-2 py-2 text-lowercase"
                      v-else-if="fileOrder"
                    >
                      {{ fileOrder.name }}</b-badge
                    >
                  </p>
                  <template v-if="productOrder.products">
                    <p
                      class="text-left text-primary fw-bolder"
                      v-for="(prod, ind) in productOrder.products"
                      :key="ind"
                    >
                      <b>N°{{ prod.quantity }}</b>
                      <span>
                        {{ prod.quantity > 1 ? "confezioni" : "confezione" }}
                      </span>
                      <span> {{ prod.Nome }} </span>
                      <b v-if="prod.generic"> - generico</b>
                    </p>
                  </template>
                  <!-- <p v-if="productOrder.products" class="text-left">
                    <b-badge variant="outline-primary" class="ml-0 mr-1 px-2 py-1 border-primary border text-primary"
                      v-for="(prod, ind) in productOrder.products" :key="ind">
                      {{ prod.Nome }}</b-badge>
                  </p> -->
                  <p v-if="productOrder.nre" class="text-left">
                    <strong>NRE:</strong> {{ productOrder.nre }}
                  </p>
                  <template v-if="productOrder.rev">
                    <p class="text-left">
                      <span class="text-primary"> REV:</span>
                      {{ productOrder.rev }}
                    </p>
                    <p />
                    <p
                      class="text-uppercase text-left border general_border p-2"
                    >
                      <b-icon
                        icon="exclamation-circle"
                        variant="primary"
                        class="mr-1"
                      ></b-icon>
                      <span class="">
                        Ricorda di portare con te il pin della ricetta quando
                        vai a ritirare l'ordine.
                      </span>
                    </p>
                  </template>
                  <p class="text-left text-capitalize">
                    <span class="text-primary">Presso: </span>
                    <span class="underlined">{{
                      selectedCompany.ff_denominazione
                    }}</span>
                    <span
                      >, {{ selectedCompany.indirizzo }}
                      {{ selectedCompany.frazione }}
                      {{ selectedCompany.comune }}</span
                    >
                  </p>
                  <p class="text-left">
                    <span class="text-primary">Data ed ora selezionate: </span>
                    <span class="underlined">
                      {{ form.data_inizio | moment("D MMM YYYY HH:mm") }}</span
                    >
                  </p>
                  <b-form-group
                    label="Se vuoi, aggiungi una nota per l'operatore"
                    label-for="subtype"
                    class="text-left fs-6"
                  >
                    <b-form-input
                      name="subtype"
                      class="mx-auto"
                      v-model="form.user_notes"
                      required
                    >
                    </b-form-input>
                  </b-form-group>
                </div>
              </b-col>
            </div>
            <div class="puntofarmaCard p-4 my-4">
              <b-col class="my-3 px-0 px-md-2">
                <p class="text-left">
                  Per procedere alla conferma dell'ordine è necessario
                  <span
                    target="_blank"
                    class="point"
                    @click="$bvModal.show(informativa[form.type].url)"
                  >
                    <u> leggere ed accettare l'informativa</u></span
                  >.
                </p>
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="data_no_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="informRead"
                  :state="informRead == true"
                >
                  Ho letto e compreso
                  <span
                    target="_blank"
                    class="text-underlined"
                    @click="$bvModal.show(informativa[form.type].url)"
                  >
                    <u>l'informativa privacy</u></span
                  >
                </b-form-checkbox>
                <b-form-checkbox
                  class="text-font-light-grey"
                  name="data_consense"
                  :value="true"
                  :unchecked-value="false"
                  v-model="informAccepted"
                  :state="informAccepted == true"
                >
                  Acconsento al trattamento dei miei dati personali per le
                  finalità indicate nell’informativa
                </b-form-checkbox>
              </b-col>
            </div>

            <b-col ref="orderConfirm" class="text-center px-0 px-md-2">
              <b-button variant="outline-danger" class="m-2" @click="resetAll"
                >Annulla</b-button
              >
              <b-button
                variant="primary text-white primary_gradient fw-bolder"
                class="m-2"
                @click="submitRequest"
                :disabled="!informAccepted || !informRead"
              >
                <span v-if="!sending">Procedi</span>
                <b-spinner v-else small></b-spinner>
              </b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="step == 6" key="step6">
          <div v-if="reservationConfirmed && newReservation">
            <div class="">
              <div
                class="d-flex justify-content-center align-items-center mb-4 py-2"
              >
                <h4 class="mb-0 mr-2">Prenotazione inoltrata</h4>
                <b-icon
                  icon="check-circle"
                  variant="secondary"
                  font-scale="2"
                ></b-icon>
              </div>
              <div class="">
                <div class="puntofarmaCard p-4">
                  <h4 class="text-prim-grad-1 text-left pb-4">Riepilogo</h4>
                  <div>
                    <div class="mt-2">
                      <p class="text-left text-capitalize">
                        <span class="text-primary">Tipo ordine:</span>
                        {{ form.type }}
                        <span class="underlined" v-if="selectedPrestazione">
                          {{ selectedPrestazione.name }}</span
                        >
                        <b-badge
                          variant="info"
                          class="px-2 py-2 text-lowercase"
                          v-else-if="fileOrder"
                        >
                          {{ fileOrder.name }}</b-badge
                        >
                      </p>
                      <template v-if="productOrder.products">
                        <p
                          class="text-left text-primary fw-bolder"
                          v-for="(prod, ind) in productOrder.products"
                          :key="ind"
                        >
                          <b>N°{{ prod.quantity }}</b>
                          <span>
                            {{
                              prod.quantity > 1 ? "confezioni" : "confezione"
                            }}
                          </span>
                          <span> {{ prod.Nome }} </span>
                          <b v-if="prod.generic"> - generico</b>
                        </p>
                      </template>
                      <!-- <p v-if="productOrder.products" class="text-left">
                        <b-badge variant="outline-primary"
                          class="ml-0 mr-1 px-2 py-1 border-primary border text-primary"
                          v-for="(prod, ind) in productOrder.products" :key="ind">
                          {{ prod.Nome }}</b-badge>
                      </p> -->
                      <p v-if="productOrder.nre" class="text-left">
                        <span class="text-primary">NRE:</span>
                        {{ productOrder.nre }}
                      </p>
                      <template v-if="productOrder.rev">
                        <p class="text-left">
                          <span class="text-primary"> REV:</span>
                          {{ productOrder.rev }}
                        </p>
                        <p />
                        <p class="text-uppercase text-left">
                          <b-icon
                            icon="exclamation-circle"
                            variant="primary"
                            class="mr-2"
                          ></b-icon>
                          <span class=""
                            >Ricorda di portare con te il pin della ricetta
                            quando vai a ritirare l'ordine.
                          </span>
                        </p>
                      </template>
                      <p class="text-left text-capitalize">
                        <span class="text-primary">Presso: </span>
                        <span class="underlined">{{
                          selectedCompany.ff_denominazione
                        }}</span>
                        <span
                          >, {{ selectedCompany.indirizzo }}
                          {{ selectedCompany.frazione }}
                          {{ selectedCompany.comune }}</span
                        >
                      </p>
                      <p class="text-left">
                        <span class="text-primary"
                          >Data ed ora selezionate:
                        </span>
                        <span class="underlined">
                          {{
                            form.data_inizio | moment("D MMM YYYY HH:mm")
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <div
                    class="pb-3 text-left"
                    v-if="
                      newReservation.resources &&
                      newReservation.resources.length > 0
                    "
                  >
                    <h6 class="text-prim-grad-1 fw-bolder text-left pb-1 pt-4">
                      Documenti Scaricabili
                    </h6>
                    <p class="text-left">
                      Sono presenti documenti correlati a questa prenotazione,
                      puoi visualizzarli cliccando su ciascuno di essi. I
                      documenti contenenti campi da compilare vanno scaricati,
                      stampati, compilati e presentati in sede di appuntamento.
                    </p>
                    <a
                      v-for="(allegato, ind) in newReservation.resources"
                      :key="ind"
                      :href="allegato.full_path"
                      target="__blank"
                      class="mr-1"
                    >
                      <b-badge
                        variant="primary"
                        class="my-2 py-2 px-3 py-md-1 px-md-2"
                        v-b-tooltip.hover
                        :title="
                          'apri ' + allegato.name + ' in un\'altra scheda'
                        "
                        >{{ allegato.name }}</b-badge
                      >
                    </a>
                  </div>
                  <div
                    class="pb-3 text-left"
                    v-if="
                      newReservation.tipo_ordine === 'prestazione' &&
                      newReservation.orderable.health_service
                        .custom_notification_text
                    "
                  >
                    <h6 class="text-prim-grad-1 fw-bolder text-left pb-1 pt-4">
                      Raccomandazioni
                    </h6>
                    <p class="text-left">
                      {{
                        newReservation.orderable.health_service
                          .custom_notification_text
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="d-flex flex-column flex-md-row align-items-center justify-content-md-around w-100 mt-4"
                >
                  <b-button
                    variant="secondary col-6 col-sm-4 col-md-3 px-0 mt-3"
                    @click="$router.push({ name: 'agenda' })"
                    >vai all'agenda
                  </b-button>
                  <b-button
                    variant="outline-primary col-6 col-sm-4 col-md-3 px-0 mt-3"
                    @click="downloadCalendar"
                    >scarica calendar
                  </b-button>
                  <b-button
                    variant="outline-primary col-6 col-sm-4 col-md-3 px-0 mt-3"
                    @click="downloadPdf"
                    >scarica promemoria
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition-group>

      <div>
        <StepperButtons
          :first="step == 0 || step == 6"
          :islast="step > 4"
          :waiting="waitForInput"
          v-on:counter-update="step = step + $event"
        />
      </div>
    </div>

    <b-modal
      size="xl"
      centered
      ref="modal-preferenze"
      title="Cerca"
      body-class="p-0 pb-4"
      hide-footer
    >
      <SearchCompany
        v-on:new-selection="updatePreferenze($event)"
        class="px-3 py-3 mt-n4"
      />
    </b-modal>
    <b-modal size="xl" centered id="info_tc" title="Informativa" hide-footer>
      <TeleconsultoPerFamiliare
        :company="selectedCompany"
      ></TeleconsultoPerFamiliare>
    </b-modal>
    <b-modal size="xl" centered id="info_prest" title="Informativa" hide-footer>
      <PrestazionePerFamiliare
        :company="selectedCompany"
      ></PrestazionePerFamiliare>
    </b-modal>
    <b-modal size="xl" centered id="info_ord" title="Informativa" hide-footer>
      <OrdinePerFamiliare :company="selectedCompany"></OrdinePerFamiliare>
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { mapState, mapActions } from "vuex"
import { reservationService } from "@/_services"
import { supportService } from "@/_services"
import NavBarNuovoOrdineMobile from "@/components/prenotazioni/NavBarNuovoOrdineMobile.vue"
import CalendarioDisponibilitaSettimanaCittadino from "@/components/prenotazioni/calendario/CalendarioDisponibilitaSettimanaCittadino.vue"
import CalendarioDisponibilitaGiornalieraCittadino from "@/components/prenotazioni/calendario/CalendarioDisponibilitaGiornalieraCittadino.vue"
import CompanyCard from "@/components/prenotazioni/CompanyCard.vue"
import SearchCompany from "@/components/home/SearchCompany.vue"
import FillSurvey from "@/components/utilities/FillSurvey.vue"
import SubjectSelection from "@/components/prenotazioni/form/SubjectSelection.vue"
import ProductSelection from "@/components/prenotazioni/form/ProductSelection.vue"
import PrestazioneSelection from "@/components/prenotazioni/form/PrestazioneSelection.vue"
import TeleconsultoSurveySelection from "@/components/prenotazioni/form/TeleconsultoSurveySelection.vue"
import StepperButtons from "@/components/prenotazioni/form/StepperButtons.vue"
import DatetimeSelection from "@/components/prenotazioni/form/DatetimeSelection.vue"
import OrdinePerFamiliare from "@/components/prenotazioni/informative/OrdinePerFamiliare.vue"
import PrestazionePerFamiliare from "@/components/prenotazioni/informative/PrestazionePerFamiliare.vue"
import TeleconsultoPerFamiliare from "@/components/prenotazioni/informative/TeleconsultoPerFamiliare.vue"

export default {
  name: "NuovoOrdine",
  components: {
    CompanyCard,
    SearchCompany,
    FillSurvey,
    SubjectSelection,
    ProductSelection,
    PrestazioneSelection,
    TeleconsultoSurveySelection,
    StepperButtons,
    CalendarioDisponibilitaGiornalieraCittadino,
    CalendarioDisponibilitaSettimanaCittadino,
    NavBarNuovoOrdineMobile,
    DatetimeSelection,
    OrdinePerFamiliare,
    PrestazionePerFamiliare,
    TeleconsultoPerFamiliare
  },
  computed: {
    checkPrecedente: function () {
      var today = moment(this.today).week()
      var start = moment(this.filters.from, "D/M/YY").week()
      return start <= today
    },

    prenotazioniEnabled: function () {
      return !(
        this.selectedCompany.stato_servizi["prenotazioni"] == undefined ||
        this.selectedCompany.stato_servizi["prenotazioni"] == 0
      )
    },
    teleconsultoEnabled: function () {
      return !(
        this.selectedCompany.stato_servizi["teleconsulto"] == undefined ||
        this.selectedCompany.stato_servizi["teleconsulto"] == 0
      )
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  },
  watch: {
    form: {
      handler(val) {
        console.log("service id value in form", val.service_id)
        // console.log(val)
      },
      deep: true
    },
    selectedCompany: function (val) {
      // console.log("SELECTED COMPANY WATCHER", val);
      this.selectCompany(val)
    },
    proceed: function (val) {
      if (val === true) {
        this.$nextTick(function () {
          // console.log(this.$refs);
          // this.$refs["moveOn"].scrollIntoView();
          // document.getElementById("moveOn").scrollIntoView();
        })
      }
    },
    step: function (val) {
      // console.log("STEP", val);
      if (val == 1) {
        // this.waitForInput = !this.form.subject_id;
        this.waitForInput = false
      }
      if (val == 2) {
        if (this.form.type === "prodotto") {
          if (this.selectedCompany.stato_servizi["prenotazioni"] == undefined) {
            this.block = true
            this.waitForInput = true
            window.scrollTo(0, 0)
            this.errorAlert("La sede selezionata non ha attivato il servizio.")
          } else if (
            this.selectedCompany.stato_servizi["prenotazioni"] == 0 ||
            this.selectedCompany.servizi_attivi_orari.prenotazioni.length == 0
          ) {
            this.block = true
            this.waitForInput = true
            window.scrollTo(0, 0)
            this.errorAlert(
              "La sede selezionata non ha impostato gli orari di disponibilità per il servizio."
            )
          } else {
            this.block = false
          }
        } else if (this.form.type === "teleconsulto") {
          if (this.selectedCompany.stato_servizi["teleconsulto"] == undefined) {
            this.block = true
            this.waitForInput = true
            window.scrollTo(0, 0)
            this.errorAlert("La sede selezionata non ha attivato il servizio.")
          } else if (
            this.selectedCompany.stato_servizi["teleconsulto"] == 0 ||
            this.selectedCompany.servizi_attivi_orari.teleconsulto.length == 0
          ) {
            this.block = true
            this.waitForInput = true
            window.scrollTo(0, 0)
            this.errorAlert(
              "La sede selezionata non ha impostato gli orari di disponibilità per il servizio."
            )
          } else {
            this.block = false
          }
        } else {
          this.block = false
          this.waitForInput = false
        }
        if (!this.selectedCompany) this.waitForInput = true
      }
      // if (val == 1 ) {
      //   this.waitForInput = !this.form.subject_id;
      // }
      if (val == 3) {
        // console.log(`waitForInput: ${this.waitForInput}, block: ${this.block}`);
        this.waitForInput = !this.form.data_inizio
      }
      if (val == 4) {
        // console.log(`waitForInput: ${this.waitForInput}, block: ${this.block}`);
        this.waitForInput = !this.form.data_inizio

        // Inizio Calendario mobile

        // this.setIntervalMobileCal();
        // this.setMobileCalDays();

        // Fine Calendario Mobile
      }
    }
  },
  data() {
    return {
      form: {
        company_id: null,
        service_id: null,
        data_inizio: null,
        data_fine: null,
        tipologia: "ordine",
        type: "prodotto",
        user_notes: null,
        filled_survey_id: null,
        order: null,
        subject_id: null,
        subject_type: "user_in_app"
      },
      steps: [
        "Tipo",
        "Per",
        "Presso",
        "Oggetto",
        "Data",
        "Conferma",
        "Riepilogo"
      ],
      innerWidth: window.innerWidth,
      interval: "",
      pickedDayIndex: "",
      weekDays: [],
      durata: 15,
      health_service_id: null,
      details: null,
      selectedCompany: null,
      selectedPrestazione: null,
      selectedProduct: null,
      selectedProducts: [],
      start: null,
      end: null,
      month: null,
      today: null,
      fields: [],
      disponibilita: null,
      selectedSlot: null,
      selectedCol: null,
      filters: {
        from: null,
        to: null
      },
      showDisponibilita: false,
      reservationConfirmed: false,
      sending: false,
      newReservation: null,
      productOrder: {
        nre: null,
        rev: null,
        resource_id: null,
        products: null
      },
      proceed: false,
      informAccepted: false,
      informRead: false,
      informativa: {
        prodotto: {
          text: " informativa prodotto",
          url: "info_ord"
        },
        prestazione: {
          text: " informativa prestazione",
          url: "info_prest"
        },
        teleconsulto: {
          text: " informativa teleconsulto",
          url: "info_tc"
        }
      },
      showSurvey: false,
      isTc: false,
      selectTcOptionDisabled: false,
      assisted: [],
      fileOrder: null,
      surveyToFill: null,
      block: false,
      step: 0,
      waitForInput: false,
      objectSetted: false
    }
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    ...mapActions("utente", ["me"]),
    enlight(comp) {
      return this.selectedCompany && comp.hashid === this.selectedCompany.hashid
        ? true
        : false
    },
    togglePreferito(azienda) {
      if (
        !this.user.farmacia_preferita ||
        azienda.hashid !== this.user.farmacia_preferita.hashid
      ) {
        this.setAsPreferito(azienda)
      }
    },
    innerWidthChanged() {
      // console.log("resize handler");
      this.innerWidth = window.innerWidth
    },
    setAsPreferito(azienda) {
      var form = {
        company: azienda.hashid,
        preferito: true
      }
      var self = this
      supportService
        .updateRelationshipWithCompany(form)
        .then(function (response) {
          self.me()
          window.scrollTo(0, 0)
          self.successAlert("preferenze aggiornate correttamente!")
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile aggiornare le preferenze")
        })
    },
    selectCompany(data) {
      console.log("SELECTED COMPANY", data)
      this.selectedCompany = data
      this.selectedPrestazione = null
      this.surveyToFill = null
      this.showDisponibilita = false
      this.form.data_inizio = null
      this.selectedSlot = null
      this.selectedCol = null
      this.form.company_id = data.hashid
      this.waitForInput = false
      this.block = false

      if (!this.isTc && this.step == 2) {
        this.form.service_id = data.servizi_attivi["prenotazioni"]
        if (data.stato_servizi["prenotazioni"] == undefined) {
          this.block = true
          this.waitForInput = true
          this.showSurvey = false
          window.scrollTo(0, 0)
          this.errorAlert("La sede selezionata non ha attivato il servizio.")
        } else if (
          data.stato_servizi["prenotazioni"] == 0 ||
          data.servizi_attivi_orari.prenotazioni.length == 0
        ) {
          this.block = true
          this.waitForInput = true
          this.showSurvey = false
          window.scrollTo(0, 0)
          this.errorAlert(
            "La sede selezionata non ha impostato gli orari di disponibilità per il servizio."
          )
        }
      } else {
        if (this.step == 2) {
          this.form.service_id = data.servizi_attivi["teleconsulto"]
          this.selectTcOptionDisabled = false
          if (data.stato_servizi["teleconsulto"] == undefined) {
            this.showSurvey = false
            this.waitForInput = true
            window.scrollTo(0, 0)
            this.errorAlert("La sede selezionata non ha attivato il servizio.")
            this.selectTcOptionDisabled = true
          } else if (
            data.stato_servizi["teleconsulto"] == 0 ||
            data.servizi_attivi_orari.teleconsulto.length == 0
          ) {
            this.showSurvey = false
            this.waitForInput = true
            window.scrollTo(0, 0)
            this.errorAlert(
              "La sede selezionata non ha impostato gli orari di disponibilità per il servizio."
            )
            this.selectTcOptionDisabled = true
          }
        }
      }
    },
    formatDisponibilita() {
      var data = {
        hash: this.selectedPrestazione.hashid,
        params: this.filters
      }
      var self = this
      reservationService
        .disponibilitaPrestazione(data)
        .then(function (res) {
          self.disponibilita = [res.data.data]

          // if (res.data.data.length == 0) {
          //   window.scrollTo(0, 0);
          //   self.errorAlert(
          //     "La prestazione scelta non ha nessuna disponibilità per la settimana corrente"
          //   );
          //   self.disponibilita = null;
          // }

          let availability = false

          if (res.data.data.length != 0) {
            Object.values(self.disponibilita[0]).forEach((element) => {
              if (element.length > 0) availability = true
            })
          }

          if (!availability) {
            self.disponibilita = []
            if (self.step == 4) {
              window.scrollTo(0, 0)
              self.errorAlert(
                "La prestazione scelta non ha nessuna disponibilità per la settimana corrente"
              )
            }
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          )
        })
    },
    formatDisponibilitaService() {
      var data = {
        hash: this.form.service_id,
        company: this.form.company_id,
        params: this.filters
      }
      var self = this
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          // console.log("disponibilità ordini");
          // console.log(res.data.data);
          self.disponibilita = [res.data.data]
          // self.setMobileCalDays();

          let availability = false

          if (res.data.data.length != 0) {
            Object.values(self.disponibilita[0]).forEach((element) => {
              if (element.length > 0) availability = true
            })
          }

          if (!availability) {
            self.disponibilita = []
            if (self.step == 4) {
              window.scrollTo(0, 0)
              self.errorAlert(
                "Nessuna disponibilità per la settimana corrente "
              )
            }
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          )
        })
    },

    checkIfSelected(col, slot) {
      if (!this.selectedCol || !this.selectedSlot) {
        return false
      }
      if (this.selectedCol == col && this.selectedSlot == slot) {
        return true
      }
    },

    resetAll() {
      switch (this.$route.params.type) {
        case "nuovo ordine":
          this.form.type = "prodotto"
          break
        case "nuova prenotazione":
          this.form.type = "prestazione"
          break
        case "nuovo teleconsulto":
          this.form.type = "teleconsulto"
          break
      }
      this.setDetail()
    },
    submitRequest() {
      this.sending = true
      var order = this.form
      if (this.form.type === "prestazione") {
        order.order = { health_service_id: this.health_service_id }
      } else if (this.form.type === "prodotto") {
        order.order = this.productOrder
      } else if (this.form.type === "teleconsulto") {
        order.order = { durata: this.durata }
      }
      var dataF = this.form.data_inizio
      order.data_inizio = dataF.format("YYYY-MM-DD HH:mm")
      // this.form.order = { health_service_id: this.health_service_id };
      // var dataF = this.form.data_inizio;
      // this.form.data_inizio = dataF.format("YYYY-MM-DD HH:mm");
      var self = this
      reservationService
        .storeReservation(order)
        .then(function (res) {
          window.scrollTo(0, 0)
          self.successAlert("Prenotazione inviata con successo!")
          self.newReservation = res.data.data
          self.reservationConfirmed = true
          self.sending = false
          self.step = 6
        })
        .catch(function (error) {
          window.scrollTo(0, 0)
          var msg = "Non è stato possibile inviare la richiesta"
          if (error.data.errors && error.data.errors.duplicate) {
            msg = error.data.errors.duplicate
          }
          self.errorAlert(msg)
          self.sending = false
        })
    },
    updatePreferenze(nuovaPreferita) {
      this.me()
      this.$refs["modal-preferenze"].hide()
      this.selectCompany(nuovaPreferita)
      this.form.company_id = nuovaPreferita.hashid
      this.form.service_id = this.isTc
        ? nuovaPreferita.servizi_attivi["teleconsulto"]
        : nuovaPreferita.servizi_attivi["prenotazioni"]
    },
    downloadPdf() {
      const hash = this.newReservation.hashid
      const fName = this.newReservation.codice_richiesta
      var self = this
      reservationService
        .scaricaPromemoria(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".pdf"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    downloadCalendar() {
      const hash = this.newReservation.hashid
      const fName = this.newReservation.codice_richiesta
      var self = this
      reservationService
        .scaricaCalendar(hash)
        .then(function (response) {
          let blob = new Blob([response.data])
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = fName + ".ics"
          link.click()
        })
        .catch(function (error) {
          self.loading = false
          window.scrollTo(0, 0)
          self.errorAlert("non è stato possibile scaricare il promemoria")
        })
    },
    handleFilledSurvey(filled) {
      this.showSurvey = false
      var temp = {
        info: this.surveyToFill.info,
        nome: this.surveyToFill.name,
        domande: filled
      }
      var formData = {
        response: JSON.stringify(temp),
        survey_id: this.surveyToFill.hashid,
        company_id: this.form.company_id
      }
      var self = this
      reservationService
        .storeFilledSurvey(formData)
        .then(function (res) {
          // console.log(res);
          self.form.filled_survey_id = res.data.data.hashid
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile inviare il questionario")
          // console.log(err);
        })
    },
    getDuration() {
      var formData = {
        hash: this.form.service_id,
        filters: {
          company: this.form.company_id,
          day: this.selectedCol
        }
      }
      var self = this
      reservationService
        .getServiceOpeningForDay(formData)
        .then(function (res) {
          // console.log(res);
          self.durata = res.data.data[0].interval
          // window.scrollTo(0, 0);
          // self.successAlert("Questionario inviato correttamente.");
          // self.survey = res.data.data;
          // self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile inviare il questionario")
          // console.log(err);
        })
    },
    setDetail() {
      this.form.data_inizio = null
      this.form.data_fine = null
      this.informAccepted = false
      this.selectedSlot = null
      this.selectedCol = null
      this.showDisponibilita = false
      this.showSurvey = false
      this.surveyToFill = null
      this.productOrder = {
        nre: null,
        rev: null,
        resource_id: null,
        products: null
      }
      this.proceed = false
      this.fileOrder = null
      this.selectedPrestazione = null
      this.health_service_id = null
      this.form.filled_survey_id = null
      this.step = 0
    },
    setRequestSubject(subject) {
      this.form.subject_id = subject.subject_id
      this.form.subject_type = subject.subject_type
    },
    setRequestType(type) {
      this.form.type = type
      this.setDetail()
    },
    setRequestProduct(products) {
      // console.log(products);
      this.waitForInput = false
      this.objectSetted = true
      this.productOrder = products
      this.proceed = true
    },
    setFileOrder(fileOrder) {
      this.fileOrder = fileOrder
    },
    setRequestPrestazione(prestazione) {
      this.objectSetted = true
      this.waitForInput = false
      this.selectedPrestazione = prestazione
      this.health_service_id = prestazione.hashid
      this.form.data_inizio = null
      this.showSurvey = prestazione.questionario !== null
      this.surveyToFill = prestazione.questionario
    },
    setRequestTcSurvey(survey) {
      // console.log(survey);
      this.waitForInput = false
      this.objectSetted = true
      this.surveyToFill = survey
      this.showSurvey = true
    }
  },
  created() {
    window.addEventListener("resize", this.innerWidthChanged)

    if (this.user.farmacia_preferita) {
      this.selectedCompany = this.user.farmacia_preferita
      this.form.company_id = this.user.farmacia_preferita.hashid
      this.form.service_id =
        this.$route.params.type == "nuovo teleconsulto"
          ? this.user.farmacia_preferita.servizi_attivi["teleconsulto"]
          : this.user.farmacia_preferita.servizi_attivi["prenotazioni"]
    }

    console.log(this.form.service_id)
    this.today = moment()
    this.start = moment().startOf("week")
    this.end = moment().endOf("week")

    this.month = moment().month()
    this.filters.from = moment().startOf("week").format("D/M/YY")
    this.filters.to = moment().endOf("week").format("D/M/YY")
    if (this.$route.params.type == "prodotto") {
      this.form.type = "prodotto"
    } else if (this.$route.params.type == "teleconsulto") {
      this.isTc = true
      this.form.type = "teleconsulto"
    } else if (this.$route.params.type == "prestazione") {
      this.form.type = "prestazione"
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.innerWidthChanged)
  },
  mounted() {
    if (this.$route.params.type == "nuovo teleconsulto") {
      this.form.type = "teleconsulto"
      this.setDetail()
    }
    if (this.$route.query.cindex != null) {
      let indx = parseInt(this.$route.query.cindex)
      this.selectCompany(this.user.my_circle[indx])
    }
  }
}
</script>
<style lang="scss" scoped>
.select__dis:disabled {
  background-color: #fff !important;
  color: inherit !important;
}

.nav-link {
  color: $prim-grad-2;
  // color: $font-grey;
  font-weight: 600;
}

.nav-link.disabled {
  color: $sec-4 !important;
}

.done > .nav-link.disabled {
  color: $prim-grad-2 !important;
}

.nav-link.active {
  color: $prim-grad-2 !important;
  font-weight: 800;
  border-bottom: 1px solid $prim-grad-2;
}

.nav-link.active.done {
  color: $prim-grad-2 !important;
  font-weight: 800;
}

.nav-item a {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

tr {
  border-bottom: 2px solid $line-grey !important;
  // border-bottom: 2px solid $font-light-grey !important;
}

tr:last-of-type {
  border-bottom: 0 solid $line-grey !important;
}

.thicker__border {
  border-width: 3px !important;
}

// @media (max-width: 768px) {
//   #step-3-conteiner {
//     max-height: 50vh;
//     overflow: auto;
//   }
// }

// @media (min-width: 769px) {
//   #step-3-conteiner {
//     max-height: 60vh;
//     overflow: auto;
//   }
// }

.btn-outline-primary {
  background-color: white !important;
}

.btn-outline-primary:hover {
  background-color: $primary !important;
}
</style>
