<template>
  <div class="px-2">
    <section class="d-md-none">
      <h3 class="mb-3">Prenotazioni</h3>
      <b-button-group class="general_border mb-4">
        <b-button
          :variant="
            $route.params.section === 'nuovo'
              ? 'secondary'
              : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'appuntamenti',
              params: { section: 'nuovo' }
            })
          "
        >
          <span>Nuova</span>
        </b-button>
        <b-button
          :variant="
            $route.params.section === 'oggi' ? 'secondary' : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'appuntamenti',
              params: { section: 'oggi' }
            })
          "
        >
          <span>Oggi</span>
        </b-button>
        <b-button
          :variant="
            $route.params.section === 'recenti'
              ? 'secondary'
              : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'appuntamenti',
              params: { section: 'recenti' }
            })
          "
        >
          <span>Recenti</span>
        </b-button>
        <b-button
          :variant="
            $route.params.section === 'storico'
              ? 'secondary'
              : 'outline-secondary'
          "
          class="text-capitalize buttonbar"
          size="sm"
          @click="
            $router.push({
              name: 'appuntamenti',
              params: { section: 'storico' }
            })
          "
        >
          <span>Storico</span>
        </b-button>
      </b-button-group>
    </section>
    <transition-group name="list" mode="out-in" appear>
      <div
        class="px-md-1 pt-md-1 px-lg-4 pt-lg-4"
        v-if="$route.params.section === 'recenti'"
        key="recenti"
      >
        <h4
          class="d-none d-md-block text-left text-prim-grad-1 mb-4 page-title"
        >
          Prenotazioni recenti
        </h4>

        <UserLatests />
      </div>
      <div
        class="px-md-1 pt-md-1 px-lg-4 pt-lg-4"
        v-else-if="$route.params.section === 'storico'"
        key="storico"
      >
        <h4
          class="d-none d-md-block text-left text-prim-grad-1 mb-4 page-title"
        >
          Storico prenotazioni
        </h4>

        <UserStorico />
      </div>
      <div
        class="px-md-1 pt-md-1 px-lg-4 pt-lg-4"
        v-if="$route.params.section === 'oggi'"
        key="oggi"
      >
        <h4
          class="d-none d-md-block text-left text-prim-grad-1 mb-4 page-title"
        >
          Prenotazioni di oggi
        </h4>

        <UserToday />
      </div>
      <div
        class="px-md-1 pt-md-1 px-lg-4 pt-lg-4"
        v-else-if="$route.params.section === 'nuovo'"
        key="nuovo"
      >
        <section class="d-none d-md-block">
          <div
            class="border d-flex justify-content-center align-items-center mb-4 bg-whitesmoke"
          >
            <h4
              class="d-none d-md-block text-prim-grad-1 py-4 mb-0 general_border"
            >
              Nuova prenotazione
            </h4>
          </div>
        </section>

        <NuovoOrdine />
      </div>
    </transition-group>
  </div>
</template>
<script>
import UserLatests from "@/components/prenotazioni/sections/cittadino/UserLatests.vue"
import UserStorico from "@/components/prenotazioni/sections/cittadino/UserStorico.vue"
import UserToday from "@/components/prenotazioni/sections/cittadino/UserToday.vue"
import NuovoOrdine from "@/components/cittadino/NuovoOrdine.vue"

export default {
  name: "PrenotazioniCittadinoMain",
  components: {
    UserLatests,
    UserToday,
    UserStorico,
    NuovoOrdine
  },
  mounted() {
    var lib = document.createElement("script")
    lib.setAttribute("id", "ls_lib")
    lib.setAttribute("type", "text/javascript")
    lib.setAttribute(
      "src",
      "https://cdn.jsdelivr.net/npm/fm.liveswitch@latest/fm.liveswitch.js"
    )
    document.head.appendChild(lib)
  },
  beforeDestroy() {
    var lib = document.getElementById("ls_lib")
    document.head.removeChild(lib)
  }
}
</script>
