var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-2"},[_c('section',{staticClass:"d-md-none"},[_c('h3',{staticClass:"mb-3"},[_vm._v("Prenotazioni")]),_c('b-button-group',{staticClass:"general_border mb-4"},[_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'nuovo'
            ? 'secondary'
            : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'appuntamenti',
            params: { section: 'nuovo' }
          })}}},[_c('span',[_vm._v("Nuova")])]),_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'oggi' ? 'secondary' : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'appuntamenti',
            params: { section: 'oggi' }
          })}}},[_c('span',[_vm._v("Oggi")])]),_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'recenti'
            ? 'secondary'
            : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'appuntamenti',
            params: { section: 'recenti' }
          })}}},[_c('span',[_vm._v("Recenti")])]),_c('b-button',{staticClass:"text-capitalize buttonbar",attrs:{"variant":_vm.$route.params.section === 'storico'
            ? 'secondary'
            : 'outline-secondary',"size":"sm"},on:{"click":function($event){return _vm.$router.push({
            name: 'appuntamenti',
            params: { section: 'storico' }
          })}}},[_c('span',[_vm._v("Storico")])])],1)],1),_c('transition-group',{attrs:{"name":"list","mode":"out-in","appear":""}},[(_vm.$route.params.section === 'recenti')?_c('div',{key:"recenti",staticClass:"px-md-1 pt-md-1 px-lg-4 pt-lg-4"},[_c('h4',{staticClass:"d-none d-md-block text-left text-prim-grad-1 mb-4 page-title"},[_vm._v(" Prenotazioni recenti ")]),_c('UserLatests')],1):(_vm.$route.params.section === 'storico')?_c('div',{key:"storico",staticClass:"px-md-1 pt-md-1 px-lg-4 pt-lg-4"},[_c('h4',{staticClass:"d-none d-md-block text-left text-prim-grad-1 mb-4 page-title"},[_vm._v(" Storico prenotazioni ")]),_c('UserStorico')],1):_vm._e(),(_vm.$route.params.section === 'oggi')?_c('div',{key:"oggi",staticClass:"px-md-1 pt-md-1 px-lg-4 pt-lg-4"},[_c('h4',{staticClass:"d-none d-md-block text-left text-prim-grad-1 mb-4 page-title"},[_vm._v(" Prenotazioni di oggi ")]),_c('UserToday')],1):(_vm.$route.params.section === 'nuovo')?_c('div',{key:"nuovo",staticClass:"px-md-1 pt-md-1 px-lg-4 pt-lg-4"},[_c('section',{staticClass:"d-none d-md-block"},[_c('div',{staticClass:"border d-flex justify-content-center align-items-center mb-4 bg-whitesmoke"},[_c('h4',{staticClass:"d-none d-md-block text-prim-grad-1 py-4 mb-0 general_border"},[_vm._v(" Nuova prenotazione ")])])]),_c('NuovoOrdine')],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }