var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showDisponibilita)?_c('b-row',{ref:"calendarchoice",staticClass:"my-2",attrs:{"cols":"1"}},[_c('h5',{staticClass:"text-prim-grad-1 text-left pb-4"},[_vm._v(" Scegli data e ora tra le disponibilità ")]),_c('b-col',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-1 pb-3"},[_c('b-button',{attrs:{"variant":"outline-secondary","size":_vm.$root.isMobile ? 'sm' : '',"type":"button","disabled":_vm.checkPrecedente},on:{"click":_vm.setPrev}},[_c('b-icon',{attrs:{"icon":"chevron-double-left"}}),_vm._v(_vm._s(!_vm.$root.isMobile ? "Precedente" : "")+" ")],1),_c('h5',{staticClass:"mb-0 text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.start,"D MMM"))+" - "+_vm._s(_vm._f("moment")(_vm.end,"D MMM"))+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"button","size":_vm.$root.isMobile ? 'sm' : ''},on:{"click":_vm.setPost}},[_vm._v(" "+_vm._s(!_vm.$root.isMobile ? "Successiva" : "")+" "),_c('b-icon',{attrs:{"icon":"chevron-double-right"}})],1)],1),_c('section',{attrs:{"id":"disponibilita-settimana-cittadino"}},[_c('b-table',{staticClass:"no-bold-th",attrs:{"borderless":"","responsive":"","table-variant":"white","fields":_vm.fields,"items":_vm.disponibilita,"foot-clone":"","small":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(1)",fn:function(row){return _vm._l((row.item['1']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(1, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(1, slot)}}},[_c('span',[_vm._v(_vm._s(slot))])])})}},{key:"cell(2)",fn:function(row){return _vm._l((row.item['2']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(2, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(2, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(3)",fn:function(row){return _vm._l((row.item['3']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(3, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(3, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(4)",fn:function(row){return _vm._l((row.item['4']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(4, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(4, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(5)",fn:function(row){return _vm._l((row.item['5']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(5, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(5, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(6)",fn:function(row){return _vm._l((row.item['6']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(6, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(6, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(7)",fn:function(row){return _vm._l((row.item['7']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(7, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(7, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"empty",fn:function(scope){return [(scope)?_c('p',{staticClass:"fw-bolder text-secondary"},[_vm._v(" Nessuna disponibilità per questa settimana. Prova a verificare le disponibilità per le prossime settimane. ")]):_vm._e()]}}],null,false,3227165026)})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }