var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showDisponibilita)?_c('b-row',{ref:"calendarchoice",staticClass:"my-2",attrs:{"cols":"1"}},[_c('h5',{staticClass:"text-prim-grad-1 text-left pb-4"},[_vm._v(" Scegli data e ora tra le disponibilità ")]),_c('p',{staticClass:"d-flex justify-content-center"},[_c('b-form-datepicker',{staticClass:"mb-2 col-7 col-lg-5",attrs:{"label-help":"Utilizza i cursori per navigare tra le date","start-weekday":"1","id":"calendario-disponibilita-mobile-datepicker","date-format-options":_vm.dateFormat,"min":_vm.maxDate,"locale":"it"},on:{"input":function($event){return _vm.updatedDatePickerDate()}},model:{value:(_vm.datePickerDate),callback:function ($$v) {_vm.datePickerDate=$$v},expression:"datePickerDate"}})],1),_c('b-col',{staticClass:"px-0"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center pt-1 pb-3"},[_c('b-icon',{attrs:{"font-scale":"1.8","icon":"arrow-left-circle","variant":_vm.checkPrecedente || _vm.loading ? 'info' : 'primary'},on:{"click":_vm.setPrev}}),_c('h5',{staticClass:"mb-0 px-3"},[_vm._v("giorno")]),_c('b-icon',{attrs:{"font-scale":"1.8","icon":"arrow-right-circle","variant":_vm.loading ? 'info' : 'primary'},on:{"click":_vm.setPost}})],1),_c('section',{attrs:{"id":"disponibilita-giornaliera-cittadino"}},[_c('b-table',{staticClass:"no-bold-th",attrs:{"borderless":"","responsive":"","table-variant":"white","fields":_vm.fields,"items":_vm.disponibilitaGiorno,"foot-clone":"","small":"","show-empty":""},scopedSlots:_vm._u([{key:"cell(1)",fn:function(row){return _vm._l((row.item['1']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(1, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(1, slot)}}},[_c('span',[_vm._v(_vm._s(slot))])])})}},{key:"cell(2)",fn:function(row){return _vm._l((row.item['2']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(2, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(2, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(3)",fn:function(row){return _vm._l((row.item['3']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(3, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(3, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(4)",fn:function(row){return _vm._l((row.item['4']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(4, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(4, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(5)",fn:function(row){return _vm._l((row.item['5']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(5, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(5, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(6)",fn:function(row){return _vm._l((row.item['6']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(6, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(6, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"cell(7)",fn:function(row){return _vm._l((row.item['7']),function(slot){return _c('p',{key:slot,staticClass:"general_border point border border-info-grad-1 slot__tm",class:_vm.checkIfSelected(7, slot)
                ? 'bg-primary text-white border border-primary'
                : 'text-dark-grey border border-info-grad-1',on:{"click":function($event){return _vm.selectDate(7, slot)}}},[_c('span',{},[_vm._v(_vm._s(slot))])])})}},{key:"empty",fn:function(scope){return [(scope)?_c('p',{staticClass:"fw-bolder text-secondary"},[_vm._v(" Nessuna disponibilità per il giorno selezionato ")]):_vm._e()]}}],null,false,2987804863)})],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }