<template>
  <div>
    <section
      class="d-md-none puntofarmaCard p-3 text-left"
      id="mobile-card-row"
    >
      <p class="d-flex justify-content-between mb-1">
        <span class="text-capitalize text-primary resev-title">{{
          prenotazione.tipo_ordine == "prestazione"
            ? prenotazione.orderable.prestazione.name
            : "Richiesta " + prenotazione.tipo_ordine
        }}</span>
        <span class="text-primary ml-1" v-if="prenotazione.unreadMessages"
          ><b-icon icon="chat-dots-fill" font-scale="1.4"></b-icon>
          <b-badge class="fs_badge ml-1" variant="danger">{{
            prenotazione.unreadMessages
          }}</b-badge>
        </span>
      </p>
      <p class="mb-1">
        <span
          class="font-weight-bolder mr-2"
          :class="'text-' + statusVariants[prenotazione.stato]"
        >
          {{ prenotazione.stato }}
        </span>
        <span>{{ formatDataNew(prenotazione.data_inizio) }}</span>
      </p>
      <p class="mb-1">
        {{ prenotazione.company.ff_denominazione }}
      </p>
      <p class="d-flex justify-content-between mb-0">
        <span class="text-secondary">#{{ prenotazione.codice_richiesta }}</span>
        <span>
          <b-button
            size="sm"
            class="mr-1"
            variant="info general_border"
            @click="$emit('show-detail')"
          >
            <b-icon icon="eye" variant="info text-black"></b-icon>
          </b-button>
          <b-dropdown
            size="sm"
            variant="primary text-white"
            right
            class="general_border primary-dropdown"
          >
            <template #button-content>
              <b-icon variant="primary text-white" icon="download"></b-icon>
            </template>
            <b-dropdown-item
              class="primary-li-hover"
              @click="$emit('download-pdf')"
              >Promemoria</b-dropdown-item
            >
            <b-dropdown-item
              class="primary-li-hover"
              @click="$emit('download-calendar')"
              >Calendar</b-dropdown-item
            >
          </b-dropdown>
        </span>
      </p>
    </section>
    <b-row
      class="desktop-card-row d-none d-md-flex puntofarmaCard p-4 text-left"
      align-v="stretch"
    >
      <b-col
        class="col-md-4 col-lgx-4 p-0 d-flex flex-column justify-content-between"
      >
        <p class="">
          <span class="text-capitalize text-primary resev-title">{{
            prenotazione.tipo_ordine == "prestazione"
              ? prenotazione.orderable.prestazione.name
              : "Richiesta " + prenotazione.tipo_ordine
          }}</span>
        </p>
        <p class="mb-0">
          <span
            class="font-weight-bolder"
            :class="'text-' + statusVariants[prenotazione.stato]"
          >
            {{ prenotazione.stato }}
          </span>
        </p>
      </b-col>
      <b-col
        class="col-md-4 col-lgx-4 p-0 d-flex flex-column justify-content-between"
      >
        <p class="mt-1">
          {{ prenotazione.company.ff_denominazione }}
        </p>
        <p class="text-primary mb-0">#{{ prenotazione.codice_richiesta }}</p>
      </b-col>
      <b-col
        class="col-md-3 col-lgx-3 p-0 d-flex flex-column justify-content-between"
      >
        <p class="mt-1">
          {{ formatDataNew(prenotazione.data_inizio) }}
        </p>
        <p class="mb-0">
          <span class="text-primary ml-1" v-if="prenotazione.unreadMessages"
            ><b-icon icon="chat-dots-fill" font-scale="1.4"></b-icon>
            <b-badge class="fs_badge ml-1" variant="danger">{{
              prenotazione.unreadMessages
            }}</b-badge>
          </span>
        </p>
      </b-col>
      <b-col
        class="col-md-1 col-lgx-1 p-0 d-flex flex-column justify-content-between h-100"
      >
        <p>&nbsp;</p>
        <p class="text-right d-flex flex-nowrap justify-content-end mb-0">
          <b-button
            size="sm"
            class="mr-1"
            variant="info border border-info-grad-1 "
            @click="$emit('show-detail')"
          >
            <b-icon icon="eye" variant="info text-black"></b-icon>
          </b-button>
          <b-dropdown
            size="sm"
            variant="primary text-white"
            right
            class="general_border primary-dropdown"
          >
            <template #button-content>
              <b-icon variant="primary text-white" icon="download"></b-icon>
            </template>
            <b-dropdown-item
              class="primary-li-hover"
              @click="$emit('download-pdf')"
              >Promemoria</b-dropdown-item
            >
            <b-dropdown-item
              class="primary-li-hover"
              @click="$emit('download-calendar')"
              >Calendar</b-dropdown-item
            >
          </b-dropdown>
        </p>
      </b-col>
      <!-- <b-col cols="4" class="px-0">
        <p class=""></p>
        <p class=""></p
      ></b-col>

      <b-col cols="3" class="px-0">
        <p class=""></p>
        <p class=""></p
      ></b-col>

      <b-col cols="1" class="px-0">
        <p class=""></p>
        <p class=""></p
      ></b-col> -->
    </b-row>
  </div>
</template>

<script>
import * as moment from "moment/moment"
import { STATUS_VARIANTS } from "@/_utils/constants.js"

export default {
  name: "CardPrenotazioneCittadinoComponent",
  props: ["prenotazione"],
  data() {
    return {
      statusVariants: STATUS_VARIANTS
    }
  },
  computed: {},
  methods: {
    formatData(data) {
      return moment(data).format(" HH:mm dddd DD MMM YYYY")
    },
    formatDataNew(data) {
      return moment(data).format(" HH:mm - DD/MM/YYYY")
    }
  }
}
</script>
<style lang="scss" scoped>
.resev-title {
  font-size: 1.03rem;
}

.desktop-card-row {
  max-width: 800px;
}

@media (min-width: 768px) {
  #desktop-card-row {
    font-size: 0.8rem;
  }
}

@media (min-width: 850px) {
  #desktop-card-row {
    font-size: 0.85rem;
  }
}

@media (min-width: 992px) {
  #desktop-card-row {
    font-size: 1rem;
  }
}
</style>
