<template>
  <b-row cols="1" v-if="showDisponibilita" class="my-2" ref="calendarchoice">
    <h5 class="text-prim-grad-1 text-left pb-4">
      Scegli data e ora tra le disponibilità
    </h5>

    <p class="d-flex justify-content-center">
      <b-form-datepicker
        label-help="Utilizza i cursori per navigare tra le date"
        start-weekday="1"
        id="calendario-disponibilita-mobile-datepicker"
        v-model="datePickerDate"
        @input="updatedDatePickerDate()"
        class="mb-2 col-7 col-lg-5"
        :date-format-options="dateFormat"
        :min="maxDate"
        locale="it"
      ></b-form-datepicker>
    </p>

    <b-col class="px-0">
      <div class="d-flex justify-content-center align-items-center pt-1 pb-3">
        <b-icon
          @click="setPrev"
          font-scale="1.8"
          icon="arrow-left-circle"
          :variant="checkPrecedente || loading ? 'info' : 'primary'"
        ></b-icon>
        <h5 class="mb-0 px-3">giorno</h5>
        <b-icon
          @click="setPost"
          font-scale="1.8"
          icon="arrow-right-circle"
          :variant="loading ? 'info' : 'primary'"
        ></b-icon>
      </div>
      <section id="disponibilita-giornaliera-cittadino">
        <b-table
          borderless
          responsive
          class="no-bold-th"
          table-variant="white"
          :fields="fields"
          :items="disponibilitaGiorno"
          foot-clone
          small
          show-empty
        >
          <template #cell(1)="row">
            <p
              @click="selectDate(1, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['1']"
              :key="slot"
              :class="
                checkIfSelected(1, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span>{{ slot }}</span>
            </p>
          </template>
          <template #cell(2)="row">
            <p
              @click="selectDate(2, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['2']"
              :key="slot"
              :class="
                checkIfSelected(2, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(3)="row">
            <p
              @click="selectDate(3, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['3']"
              :key="slot"
              :class="
                checkIfSelected(3, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(4)="row">
            <p
              @click="selectDate(4, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['4']"
              :key="slot"
              :class="
                checkIfSelected(4, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(5)="row">
            <p
              @click="selectDate(5, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['5']"
              :key="slot"
              :class="
                checkIfSelected(5, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(6)="row">
            <p
              @click="selectDate(6, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['6']"
              :key="slot"
              :class="
                checkIfSelected(6, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(7)="row">
            <p
              @click="selectDate(7, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['7']"
              :key="slot"
              :class="
                checkIfSelected(7, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #empty="scope">
            <p class="fw-bolder text-secondary" v-if="scope">
              Nessuna disponibilità per il giorno selezionato
            </p>
          </template>
        </b-table>
      </section>
    </b-col>
  </b-row>
</template>

<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "CalendarioDisponibilitaGionrnalieraCittadinoComponent",
  props: ["form", "selectedPrestazione"],
  data() {
    return {
      dateFormat: {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric"
      },
      maxDate: new Date(),
      datePickerDate: new Date(),
      loading: false,
      showDisponibilita: false,
      start: moment().startOf("week"),
      end: moment().endOf("week"),
      fields: [],
      currentDayIndex: moment().day(),
      disponibilita: [],
      selectedSlot: null,
      selectedCol: null,
      today: moment(),
      filters: {
        from: moment().startOf("week").format("D/M/YY"),
        to: moment().endOf("week").format("D/M/YY")
      }
    }
  },
  created() {
    this.fetchData()
    this.currentDayIndex = this.today.day()
    console.log(this.currentDayIndex)
    this.setupCalendar()

    this.filters.from = moment(this.start)
      .add(this.currentDayIndex - 1, "day")
      .format("D/M/YY")

    this.datePickerDate = moment(this.start)
      .add(this.currentDayIndex - 1, "day")
      .format("YYYY-MM-DD")

    this.showDisponibilita = true
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
      errorAlert: "alert/error",
      successAlert: "alert/success",
      infoAlert: "alert/info"
    }),
    updatedDatePickerDate() {
      const isSameWeek = this.start
        .startOf("week")
        .isSame(moment(this.datePickerDate).startOf("week"))
      this.start = moment(this.datePickerDate).startOf("week")
      this.end = moment(this.datePickerDate).endOf("week")
      this.currentDayIndex = moment(this.datePickerDate).day()
      console.log("Current Day Index", this.currentDayIndex)
      this.filters.from = moment(this.start)
        .add(this.currentDayIndex - 1, "day")
        .format("D/M/YY")
      if (!isSameWeek) {
        this.fetchData()
      }
      this.fetchData()
      this.setupCalendar()
    },
    fetchData() {
      if (this.form.type === "prestazione") {
        this.formatDisponibilita()
      } else {
        this.formatDisponibilitaService()
      }
    },
    formatDisponibilita() {
      this.loading = true
      var data = {
        hash: this.selectedPrestazione.hashid,
        params: this.filters
      }
      var self = this
      reservationService
        .disponibilitaPrestazione(data)
        .then(function (res) {
          self.disponibilita = [res.data.data]

          let availability = false

          if (res.data.data.length != 0) {
            Object.values(self.disponibilita[0]).forEach((element) => {
              if (element.length > 0) availability = true
            })
          }

          if (!availability) {
            self.disponibilita = []
            // if (self.step == 4) {
            //   window.scrollTo(0, 0)
            //   self.errorAlert(
            //     "La prestazione scelta non ha nessuna disponibilità per la settimana corrente"
            //   )
            // }
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          // self.errorAlert(
          //   "Non è stato possibile trovare disponibilità per questo servizio"
          // )
        })
        .finally(() => {
          self.loading = false
        })
    },
    checkIfSelected(col, slot) {
      if (!this.selectedCol || !this.selectedSlot) {
        return false
      }
      if (this.selectedCol == col && this.selectedSlot == slot) {
        return true
      }
    },
    formatDisponibilitaService() {
      this.loading = true
      var data = {
        hash: this.form.service_id,
        company: this.form.company_id,
        params: this.filters
      }
      var self = this
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          // console.log("disponibilità ordini");
          // console.log(res.data.data);
          self.disponibilita = [res.data.data]
          // self.setMobileCalDays();

          let availability = false

          if (res.data.data.length != 0) {
            Object.values(self.disponibilita[0]).forEach((element) => {
              if (element.length > 0) availability = true
            })
          }

          if (!availability) {
            self.disponibilita = []
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          // self.errorAlert(
          //   "Non è stato possibile trovare disponibilità per questo servizio"
          // )
        })
        .finally(() => {
          self.loading = false
        })
    },
    setPrev() {
      this.selectedSlot = null
      this.selectedCol = null
      if (this.currentDayIndex === 1) {
        this.currentDayIndex = 7
        this.start.subtract(1, "weeks")
        this.end.subtract(1, "weeks")
        this.filters.from = moment(this.start)
          .add(this.currentDayIndex - 1, "days")
          .format("D/M/YY")
        this.datePickerDate = moment(this.start)
          .add(this.currentDayIndex - 1, "days")
          .format("YYYY-MM-DD")
        this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
        this.fetchData()
      } else {
        --this.currentDayIndex
        this.filters.from = moment(this.start).add(
          this.currentDayIndex - 1,
          "days"
        )
        this.datePickerDate = moment(this.start)
          .add(this.currentDayIndex - 1, "days")
          .format("YYYY-MM-DD")
      }
      this.selectedSlot = null
      this.selectedCol = null
      this.setupCalendar()
    },
    setPost() {
      this.selectedSlot = null
      this.selectedCol = null

      if (this.currentDayIndex === 7) {
        this.start.add(1, "weeks")
        this.end.add(1, "weeks")
        this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
        this.datePickerDate = moment(this.start)
          .startOf("week")
          .format("YYYY-MM-DD")
        this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
        this.currentDayIndex = 1
        this.fetchData()
      } else {
        ++this.currentDayIndex
        this.filters.from = moment(this.start).add(
          this.currentDayIndex - 1,
          "days"
        )
        this.datePickerDate = moment(this.start)
          .add(this.currentDayIndex - 1, "days")
          .format("YYYY-MM-DD")
      }
      this.setupCalendar()
    },
    setupCalendar() {
      this.selectedSlot = null
      this.selectedCol = null
      var fields = []
      // for (let x = 0; x <= 6; x++) {
      let startDay = moment(this.start)
      let todayDay = moment(this.today).format("ddd DD/M")
      let date = startDay
        .add(this.currentDayIndex - 1, "days")
        .format("ddd DD/M")
      let variant = undefined
      if (todayDay === date) {
        variant = "sec-4"
      }
      let chiave = this.currentDayIndex
      let newField = {
        key: chiave.toString(),
        label: "",
        variant: variant
      }
      fields.push(newField)
      // }
      this.fields = fields
      this.showDisponibilita = true
    },
    selectDate(col, slot) {
      this.selectedCol = col
      this.selectedSlot = slot
      let startDay = moment(this.start)
      let date = startDay.add(col - 1, "days").format("ddd DD/MM/YYYY")
      date += " " + slot
      const data_inizio = moment(date, "ddd DD/MM/YYYY HH:mm")
      this.$emit("update-data-inizio", data_inizio)
      if (this.isTc) {
        this.getDuration()
      }
    },
    getDuration() {
      var formData = {
        hash: this.form.service_id,
        filters: {
          company: this.form.company_id,
          day: this.selectedCol
        }
      }
      var self = this
      reservationService.getServiceOpeningForDay(formData).then(function (res) {
        // console.log(res);
        self.$emit("update-durata", res.data.data[0].interval)
        // window.scrollTo(0, 0);
        // self.successAlert("Questionario inviato correttamente.");
        // self.survey = res.data.data;
        // self.consent = self.survey.info == null;
      })
      // .catch(function (err) {
      //   window.scrollTo(0, 0)
      //   self.errorAlert("Non è stato possibile inviare il questionario")
      // })
    }
  },
  computed: {
    checkPrecedente: function () {
      var currentDay = moment(this.start)
        .add(this.currentDayIndex - 1, "days")
        .format("YYYY-MM-DD")
      var today = moment().format("YYYY-MM-DD")
      return currentDay <= today
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"]),
    dayLabel() {
      return moment(this.filters.from, "D/M/YY")
        .format("dddd D MMM")
        .toLowerCase()
    },
    disponibilitaGiorno() {
      if (
        !this.disponibilita ||
        !this.disponibilita[0] ||
        !this.disponibilita[0][this.currentDayIndex] ||
        this.disponibilita[0][this.currentDayIndex].length == 0
      ) {
        return []
      }
      const disponibilitaObject = {}
      disponibilitaObject[this.currentDayIndex] =
        this.disponibilita[0][this.currentDayIndex]
      return [disponibilitaObject]
    }
  }
}
</script>
<style lang="scss">
#disponibilita-giornaliera-cittadino {
  max-height: 30vh !important;
  overflow: auto;
}

#calendario-disponibilita-mobile-datepicker__value_ {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 600;
  color: black;
  font-size: 20px;
}

button#calendario-disponibilita-mobile-datepicker {
  padding: 0 !important;
}

div#calendario-disponibilita-mobile-datepicker__outer_ {
  flex-basis: initial;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #00000022;
  border-radius: 8px;
  border-radius: 0.15rem;
  opacity: 1;
  transition: all 200ms ease-in;
  border: 0;
}

.form-control#calendario-disponibilita-mobile-datepicker__outer_ {
  min-width: 70vw;
}

@media (max-width: 450px) {
  .form-control#calendario-disponibilita-mobile-datepicker__outer_ {
    min-width: 80vw;
  }
}
</style>
