<template>
  <b-row cols="1" v-if="showDisponibilita" class="my-2" ref="calendarchoice">
    <h5 class="text-prim-grad-1 text-left pb-4">
      Scegli data e ora tra le disponibilità
    </h5>

    <b-col class="px-0">
      <div class="d-flex justify-content-between align-items-center pt-1 pb-3">
        <b-button
          variant="outline-secondary"
          :size="$root.isMobile ? 'sm' : ''"
          type="button"
          @click="setPrev"
          :disabled="checkPrecedente"
        >
          <b-icon icon="chevron-double-left"></b-icon
          >{{ !$root.isMobile ? "Precedente" : "" }}
        </b-button>
        <h5 class="mb-0 text-capitalize">
          {{ start | moment("D MMM") }} - {{ end | moment("D MMM") }}
        </h5>
        <b-button
          variant="outline-secondary"
          type="button"
          :size="$root.isMobile ? 'sm' : ''"
          @click="setPost"
        >
          {{ !$root.isMobile ? "Successiva" : "" }}
          <b-icon icon="chevron-double-right"></b-icon>
        </b-button>
      </div>
      <section id="disponibilita-settimana-cittadino">
        <b-table
          borderless
          responsive
          class="no-bold-th"
          table-variant="white"
          :fields="fields"
          :items="disponibilita"
          foot-clone
          small
          show-empty
        >
          <template #cell(1)="row">
            <p
              @click="selectDate(1, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['1']"
              :key="slot"
              :class="
                checkIfSelected(1, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span>{{ slot }}</span>
            </p>
          </template>
          <template #cell(2)="row">
            <p
              @click="selectDate(2, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['2']"
              :key="slot"
              :class="
                checkIfSelected(2, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(3)="row">
            <p
              @click="selectDate(3, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['3']"
              :key="slot"
              :class="
                checkIfSelected(3, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(4)="row">
            <p
              @click="selectDate(4, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['4']"
              :key="slot"
              :class="
                checkIfSelected(4, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(5)="row">
            <p
              @click="selectDate(5, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['5']"
              :key="slot"
              :class="
                checkIfSelected(5, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(6)="row">
            <p
              @click="selectDate(6, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['6']"
              :key="slot"
              :class="
                checkIfSelected(6, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #cell(7)="row">
            <p
              @click="selectDate(7, slot)"
              class="general_border point border border-info-grad-1 slot__tm"
              v-for="slot in row.item['7']"
              :key="slot"
              :class="
                checkIfSelected(7, slot)
                  ? 'bg-primary text-white border border-primary'
                  : 'text-dark-grey border border-info-grad-1'
              "
            >
              <span class="">{{ slot }}</span>
            </p>
          </template>
          <template #empty="scope">
            <p class="fw-bolder text-secondary" v-if="scope">
              Nessuna disponibilità per questa settimana. Prova a verificare le
              disponibilità per le prossime settimane.
            </p>
          </template>
        </b-table>
      </section>
    </b-col>
  </b-row>
</template>

<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"
import { mapState, mapActions } from "vuex"
export default {
  name: "CalendarioDisponibilitaSettimanaCittadinoComponent",
  props: ["form", "selectedPrestazione"],
  data() {
    return {
      showDisponibilita: false,
      start: moment().startOf("week"),
      end: moment().endOf("week"),
      fields: [],
      disponibilita: [],
      selectedSlot: null,
      selectedCol: null,
      today: moment(),
      filters: {
        from: moment().startOf("week").format("D/M/YY"),
        to: moment().endOf("week").format("D/M/YY")
      },
      month: moment().month()
    }
  },
  created() {
    if (this.form.type === "prestazione") {
      this.formatDisponibilita()
    } else {
      this.formatDisponibilitaService()
    }
    this.setupCalendar()
    this.showDisponibilita = true
  },
  methods: {
    formatDisponibilita() {
      var data = {
        hash: this.selectedPrestazione.hashid,
        params: this.filters
      }
      var self = this
      reservationService
        .disponibilitaPrestazione(data)
        .then(function (res) {
          self.disponibilita = [res.data.data]

          // if (res.data.data.length == 0) {
          //   window.scrollTo(0, 0);
          //   self.errorAlert(
          //     "La prestazione scelta non ha nessuna disponibilità per la settimana corrente"
          //   );
          //   self.disponibilita = null;
          // }

          let availability = false

          if (res.data.data.length != 0) {
            Object.values(self.disponibilita[0]).forEach((element) => {
              if (element.length > 0) availability = true
            })
          }

          if (!availability) {
            self.disponibilita = []
            if (self.step == 4) {
              window.scrollTo(0, 0)
              self.errorAlert(
                "La prestazione scelta non ha nessuna disponibilità per la settimana corrente"
              )
            }
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          )
        })
    },
    checkIfSelected(col, slot) {
      if (!this.selectedCol || !this.selectedSlot) {
        return false
      }
      if (this.selectedCol == col && this.selectedSlot == slot) {
        return true
      }
    },
    formatDisponibilitaService() {
      var data = {
        hash: this.form.service_id,
        company: this.form.company_id,
        params: this.filters
      }
      var self = this
      reservationService
        .disponibilitaServizio(data)
        .then(function (res) {
          // console.log("disponibilità ordini");
          // console.log(res.data.data);
          self.disponibilita = [res.data.data]
          // self.setMobileCalDays();

          let availability = false

          if (res.data.data.length != 0) {
            Object.values(self.disponibilita[0]).forEach((element) => {
              if (element.length > 0) availability = true
            })
          }

          if (!availability) {
            self.disponibilita = []
            if (self.step == 4) {
              window.scrollTo(0, 0)
              self.errorAlert(
                "Nessuna disponibilità per la settimana corrente "
              )
            }
          }
        })
        .catch(function (error) {
          self.disponibilita = []
          window.scrollTo(0, 0)
          self.errorAlert(
            "Non è stato possibile trovare disponibilità per questo servizio"
          )
        })
    },
    setPrev() {
      this.start.subtract(1, "weeks")
      this.end.subtract(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.selectedSlot = null
      this.selectedCol = null
      this.setupCalendar()
      // this.setIntervalMobileCal();
    },
    setPost() {
      this.start.add(1, "weeks")
      this.end.add(1, "weeks")
      this.filters.from = moment(this.start).startOf("week").format("D/M/YY")
      this.filters.to = moment(this.end).endOf("week").format("D/M/YY")
      this.selectedSlot = null
      this.selectedCol = null
      this.setupCalendar()
      // this.setIntervalMobileCal();
    },
    setupCalendar() {
      this.selectedSlot = null
      this.selectedCol = null
      var fields = []
      for (let x = 0; x <= 6; x++) {
        let startDay = moment(this.start)
        let todayDay = moment(this.today).format("ddd DD/M")
        let date = startDay.add(x, "days").format("ddd DD/M")
        let variant = undefined
        if (todayDay === date) {
          variant = "sec-4"
        }
        let chiave = x + 1
        let newField = {
          key: chiave.toString(),
          label: date,
          variant: variant
        }
        fields.push(newField)
      }
      this.fields = fields
      if (this.form.type === "prestazione") {
        this.formatDisponibilita()
      } else {
        this.formatDisponibilitaService()
      }
      this.showDisponibilita = true
    },
    selectDate(col, slot) {
      this.selectedCol = col
      this.selectedSlot = slot
      let startDay = moment(this.start)
      let date = startDay.add(col - 1, "days").format("ddd DD/MM/YYYY")
      date += " " + slot
      const data_inizio = moment(date, "ddd DD/MM/YYYY HH:mm")
      this.$emit("update-data-inizio", data_inizio)
      if (this.isTc) {
        this.getDuration()
      }
    },
    getDuration() {
      var formData = {
        hash: this.form.service_id,
        filters: {
          company: this.form.company_id,
          day: this.selectedCol
        }
      }
      var self = this
      reservationService
        .getServiceOpeningForDay(formData)
        .then(function (res) {
          // console.log(res);
          self.$emit("update-durata", res.data.data[0].interval)
          // window.scrollTo(0, 0);
          // self.successAlert("Questionario inviato correttamente.");
          // self.survey = res.data.data;
          // self.consent = self.survey.info == null;
        })
        .catch(function (err) {
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile inviare il questionario")
          // console.log(err);
        })
    }
  },
  computed: {
    checkPrecedente: function () {
      var today = moment(this.today).week()
      var start = moment(this.filters.from, "D/M/YY").week()
      return start <= today
    },
    ...mapState({
      alert: (state) => state.alert
    }),
    ...mapState("utente", ["status", "user"])
  }
}
</script>
<style scoped lang="scss">
#disponibilita-settimana-cittadino {
  max-height: 45vh !important;
  overflow: auto;
}
</style>
