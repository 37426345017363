<template>
  <section id="bar-container" class="p-2 ml-n2 mb-n2">
    <div id="steps-container" class="">
      <span
        v-for="(step, index) in steps"
        :key="index"
        class="general_border_11 py-2 font-weight-bolder"
        :class="{ 'bg-primary text-white': index === currentStep }"
        >{{ step }}</span
      >
    </div>
  </section>
</template>

<script>
export default {
  name: "NavBarNuovoOrdineMobileComponent",
  props: ["currentStep", "steps"],
  data() {
    return {}
  },
  computed: {},
  watch: {
    currentStep() {
      document.getElementById(
        "steps-container"
      ).style.transform = `translateX(-${this.currentStep * 131}px)`
    }
  }
}
</script>

<style scoped lang="scss">
#bar-container {
  overflow: hidden;
  border-radius: 11px;
}

#steps-container {
  box-shadow: 0px 2px 8px #00000022;
  background-color: white;
  border-radius: 11px;
  display: inline-flex;
  flex-wrap: nowrap;
  transform: translateX(0px);
  transition: all 0.5s ease;
}

span {
  color: rgba(87, 87, 87, 0.25);
  border-radius: 11px;
  width: 130px;
}
</style>
