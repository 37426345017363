<template>
  <div class="col-12 px-md-0 px-1 d-flex my-4">
    <template v-if="$root.isMobile">
      <b-icon
        class="ml-0 mr-auto font-weight-bolder text-primary point"
        v-if="!first"
        icon="arrow-left-circle"
        variant="primary"
        @click="subtract"
        font-scale="3.5"
      >
        Indietro
      </b-icon>
      <b-icon
        class="mr-0 ml-auto font-weight-bolder text-primary general_border point"
        v-if="!islast && !waiting"
        icon="arrow-right-circle"
        variant="primary"
        @click="add"
        font-scale="3.5"
      >
        Avanti
      </b-icon>
    </template>
    <template v-else>
      <b-button
        class="ml-0 mr-auto font-weight-bolder"
        v-if="!first"
        variant="outline-primary"
        @click="subtract"
      >
        Indietro
      </b-button>
      <b-button
        class="mr-0 ml-auto font-weight-bolder"
        v-if="!islast && !waiting"
        variant="outline-primary"
        @click="add"
      >
        Avanti
      </b-button>
    </template>
  </div>
</template>
<script>
export default {
  name: "StepperButtons",
  props: ["first", "islast", "waiting"],
  data() {
    return {
      count: 1
    }
  },
  // watch: {
  //   count: function (val) {
  //     console.log("emitting val", val);
  //     this.$emit("counter-update", val);
  //   },
  // },
  methods: {
    add() {
      this.$emit("counter-update", +1)
    },
    subtract() {
      this.$emit("counter-update", -1)
    }
  },
  created() {
    this.islast
  }
}
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  background-color: white;
}

.btn-outline-primary:hover {
  background-color: $primary;
}
</style>
